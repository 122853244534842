import React, { FC, useEffect, useRef } from 'react'
import anime from 'animejs/lib/anime.es.js'
import { useInView } from 'react-intersection-observer'
import { SecondarySectionTemplate } from '../styles'

import { Typography } from '../../../common/Typography'
import { Card } from '../../../entities'
import { CardProps } from '../../../entities/Card/types'
import { Button, Stack } from '../../../common'

import DotsBg from '../../../../assets/images/dots.svg'
import RevenueIcon from '../../../../assets/icons/revenue-icon.png'
import MoneyIcon from '../../../../assets/icons/money-icon.png'
import FoldersIcon from '../../../../assets/icons/folders-icon.png'
import InfinityIcon from '../../../../assets/icons/infinity-icon.png'
import { OpacityTransition } from '../../../animations'
import { openCalendlyWidget } from '../../../../utils'

const AT_A_GLANCE_CARDS: CardProps[] = [
  {
    imgSrc: RevenueIcon,
    title: 'Revenue growth',
    description:
      'Industry leading record for improving practice collections and practice profitability.',
  },
  {
    imgSrc: MoneyIcon,
    title: 'Payroll savings',
    description:
      'Decrease administrative costs, lower overhead, and repurpose limited internal resources.',
  },
  {
    imgSrc: FoldersIcon,
    title: 'Scalability',
    description:
      'Rcm-x primes your practice for growth by eliminating redundant and inefficient internal processes.',
  },
  {
    imgSrc: InfinityIcon,
    title: 'All inclusive functionality',
    description: 'Beyond just billing, RCM-X solutions include:',
    points: [
      'Prior auths & eligibility',
      'Credentialing patient Collections',
      'Contract-renegotiation',
    ],
  },
]

export const SecondSection: FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })

  const evenAnimation = useRef<any>(null)
  const oddAnimation = useRef<any>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      oddAnimation.current = anime({
        targets: ['.card-anime-1', '.card-anime-3'],
        easing: 'easeInOutCubic',
        translateX: ['-350px', 0],
        opacity: [0, 1],
        delay: (_, i) => 200 * i,
        duration: 2000,
      })
      evenAnimation.current = anime({
        targets: ['.card-anime-2', '.card-anime-4'],
        easing: 'easeInOutCubic',
        translateX: ['350px', 0],
        opacity: [0, 1],
        delay: (_, i) => 200 * i,
        duration: 2000,
      })
    })
  }, [])

  useEffect(() => {
    if (inView) {
      oddAnimation.current?.play()
      evenAnimation.current?.play()
    }
  }, [inView])

  return (
    <SecondarySectionTemplate bgSize={{ mobile: 'auto', desktop: 'auto' }} background={`url(${DotsBg})`}>
      <Stack direction="column" gap={{ mobile: "24px", desktop: "80px" }}>
        <Typography.Title size="small" as="h2">
          At a glance:
        </Typography.Title>
        <div ref={ref}>
          <Stack
            maxWidth="1140px"
            direction="column"
            gap={{ mobile: "24px", desktop: "80px" }}
            margin="0 auto"
          >
            <Stack direction={{ mobile: 'column', desktop: 'row' }} gap={{ mobile: '42px', desktop: '100px' }} align="flex-start">
                {AT_A_GLANCE_CARDS.slice(0, 2).map((card, i) => (
                  <Card
                    className={`card-anime-${i + 1}`}
                    key={card.title}
                    {...card}
                  />
                ))}
            </Stack>
            <Stack direction={{ mobile: 'column', desktop: 'row' }} gap={{ mobile: '42px', desktop: '100px' }} align="flex-start">
              {AT_A_GLANCE_CARDS.slice(2, 4).map((card, i) => (
                <Card
                  className={`card-anime-${i + 1}`}
                  key={card.title}
                  {...card}
                />
              ))}
            </Stack>
          </Stack>
        </div>
        <Stack justify="center" align="center">
          <OpacityTransition>
            <Button onClick={openCalendlyWidget} theme="tertiary">
              Talk To Sales
            </Button>
          </OpacityTransition>
        </Stack>
      </Stack>
    </SecondarySectionTemplate>
  )
}
