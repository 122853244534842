import React, { FC } from 'react'
import styled from 'styled-components'
import { StepCardProps } from './types'
import { Typography } from '../../common/Typography'
import { Stack } from '../../common'
import { ReactComponent as BubblesArrow } from '../../../assets/icons/bubbles-arrow.svg'
import Bubble from '../../../assets/images/bubble-empty.png'

const Card = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-image: url(${Bubble});
  background-size: cover;
  border-radius: 12px;
  padding: 48px;
  position: relative;
  text-align: center;
  cursor: pointer;
`

const Arrow = styled.div`
  padding: 24px;
  transform: rotate(180deg);
`

export const StepCardColumn: FC<StepCardProps> = ({
  Icon,
  title,
  description,
  hideArrow,
}) => {

  return (
    <Stack
      direction='column'
      align="center"
      justify="center"
      gap="10px"
      maxWidth="400px"
    >
      <Card>
        {Icon && <Icon />}
      </Card>

      <Typography.Text size="small" as="h4" fontWeight={800} color="#fff" style={{ textTransform: 'capitalize', flexShrink: 0 }}>
        {title}
      </Typography.Text>

      <Typography.Text size="extraSmall" color="#fff" >
        {description}
      </Typography.Text>

      {!hideArrow && <Arrow>
        <BubblesArrow/>
      </Arrow>}
    </Stack>
  )
}
