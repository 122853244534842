export const PAGE_ROUTES = {
  Home: '/',
  HowItsWork: '/how-its-works',
  FrequentlyAskedQuestions: '/frequently-asked-questions',
  Blog: '/blog',
  BlogItem: '/blog/:id',
  Contact: '/contact',
  PrivacyPolicy: '/privacy-policy',
  TermsOfService: '/terms-of-service',
}
