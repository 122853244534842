import styled from 'styled-components'
import { NavBarProps } from './types'
import { breakpoints } from '../../../theme'

export const NavStyled = styled.nav<Pick<NavBarProps, 'inBurger' | 'inFooter'>>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ inFooter }) => (inFooter ? 'center' : 'space-between')};
  gap: ${({ inFooter }) => (inFooter ? '32px' : 'unset')};
  padding: 10px;
  ${breakpoints.down('large')} {
    flex-direction: ${({ inBurger }) => (inBurger ? 'column' : 'row')};
    gap: ${({ inFooter, inBurger }) =>
      inFooter ? '14px' : inBurger ? '36px' : 'unset'};
  };
  text-transform: capitalize;
`
