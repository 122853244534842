import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { PAGE_ROUTES } from '../../../router'
import LogoImg from '../../../assets/images/logo.png'
import { breakpoints } from '../../../theme'

const LogoLink = styled(Link)<{ isStatic?: boolean }>`
  // position: ${({ isStatic }) => (isStatic ? 'static' : 'absolute')};
  // top: 50%;
  // transform: ${({ isStatic }) => (isStatic ? 'unset' : 'translateY(-50%)')};
  // ${breakpoints.down('large')} {
  //   position: static;
  //   transform: unset;
  // }
`
const Logo = styled.img<{ size?: 'l' }>`
  max-width: ${({ size }) => (size === 'l' ? '220px': '165px')};
  ${breakpoints.down('large')} {
    max-width: 150px;
  }
`
export const HeaderLogo: FC<{ isStatic?: boolean, size?: 'l' }> = ({ isStatic, size }) => {
  return (
    <LogoLink isStatic={isStatic} to={PAGE_ROUTES.Home}>
      <Logo src={LogoImg} size={size}/>
    </LogoLink>
  )
}
