import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { CompanyCard, StepCard, StepCardColumn } from '../../entities'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile-colored-icon.svg'
import { ReactComponent as DataIcon } from '../../../assets/icons/data-transference-icon.svg'
import { ReactComponent as DocumentsCheckIcon } from '../../../assets/icons/documents-check-icon.svg'
import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg'
import { ReactComponent as PCollIcon } from '../../../assets/icons/p-coll-icon.svg'
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive-icon.svg'

import { StepCardProps } from '../../entities/StepCard/types'
import { useMediaQuery } from '@uidotdev/usehooks'

const CardsContainer = styled.div<{isColumn?: boolean}>`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  gap: ${({ isColumn }) => isColumn ? '0' : '42px'};
  padding: 0 10px;
`

const Row = styled.div<{ index?: number, isColumn?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isColumn }) => isColumn ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  gap: ${({ index, isColumn }) => isColumn ? '0' : index === 1 ? '60px' : '120px'};

  & .hide {
    opacity: 0;
  }
`

const DATA: (
  | (StepCardProps & { type: 'bubble' | 'description' })
)[][] = [
  [
    {
      Icon: ProfileIcon,
      title: 'Patient Seen',
      description: 'Claim drafts are made days ahead of scheduled appointments, so we take over as soon as the patient walks out the door.',
      type: 'bubble',
      arrow: {
        bottom: '-40px',
        left: '-50px',
        transform: 'rotate(72deg) scale(1, -1)',
      },
    },
    {
      Icon: ArchiveIcon,
      title: 'Claim archived',
      description: 'Completed claims are closed and all file data is put in storage.',
      type: 'bubble',
      arrow: {
        bottom: '-35px',
        right: '-40px',
        transform: 'rotate(170deg) scale(1, -1)',
      },
    },
  ],
  [
    {
      Icon: DataIcon,
      title: 'Data transfer',
      description: 'Your EMR is configured to send our system data when patients are scheduled, so claims can be submitted right after the procedure.',
      type: 'bubble',
    },
    {
      type: 'description',
    },
    {
      Icon: PCollIcon,
      title: 'Patient Collections',
      description: 'Once ERAs are accepted our collections team invoices the patient. They will take patient calls and proactively make collections on overdue balances.',
      type: 'bubble',
      arrow: {
        bottom: '-84px',
        left: '70px',
        transform: 'rotate(250deg) scale(1, -1)',
      },
    },
  ],
  [
    {
      Icon: FileIcon,
      title: 'Claims processing',
      description: 'Before submission all claims are re-checked and scrubbed for data accuracy, patient eligibility, and missing codes/modifiers.',
      type: 'bubble',
      arrow: {
        left: '-40px',
        top: '-40px',
        transform: 'rotate(355deg) scale(1, -1)',
      },
    },
    {
      Icon: DocumentsCheckIcon,
      title: 'Denials and appeals',
      description: 'In our data driven approach we compare denials to historic examples that have the same payor, CPT, and denial codes. We know exactly when to keep pushing and when to settle.',
      type: 'bubble',
      arrow: {
        left: '-90px',
        top: '50%',
        transform: 'rotate(301deg) scale(1, -1)',
      },
    },
  ]
]

export const StepsCards: FC = () => {
  const isColumn = useMediaQuery('screen and (max-width: 1540px)')
  const [title, setTitle] = useState<string | undefined>('')
  const [description, setDescription] = useState<string | undefined>('')

  const onHover = ({ title, description }: { title?: string, description?: string }) => {
    setTitle(title)
    setDescription(description)
  }

  return (
    <CardsContainer isColumn={isColumn}>
      {DATA.map((row, rowIndex) => (
        <Row key={rowIndex} index={rowIndex} isColumn={isColumn}>
          {row.map((card, cardIndex) => {
            if (card.type === 'description' && !isColumn) {
              return <CompanyCard title={title} description={description}/>
            }

            if (card.type === 'bubble' && isColumn) {
              const hideArrow = rowIndex === (DATA.length - 1) && cardIndex === (row.length -1)

              return (
                <StepCardColumn
                  key={card.title}
                  title={card.title}
                  Icon={card.Icon}
                  description={card.description}
                  hideArrow={hideArrow}
                />
              )
            }

            if (card.type === 'bubble') {
              return (
                <StepCard
                  key={card.title}
                  title={card.title}
                  Icon={card.Icon}
                  description={card.description}
                  direction={isColumn ? 'column-reverse' : row.length - 1 === cardIndex ? 'row-reverse' : 'row'}
                  onHover={onHover}
                  arrow={card.arrow}
                />
              )
            }
          })}
        </Row>
      ))}
    </CardsContainer>
  )
}
