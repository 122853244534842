import React, { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { NavLinkProps } from './types'
import { NavLinkStyled, NavLinkEmptyStyled } from './styled'

export const NavLink: FC<PropsWithChildren<NavLinkProps>> = ({
  children,
  to,
  isActive,
  inFooter,
  isDropDown
}) => {
  const NavElem = to ? NavLinkStyled : NavLinkEmptyStyled
  return (
    <NavElem
      to={to}
      inFooter={inFooter}
      isActive={inFooter ? false : isActive}
      isDropDown={isDropDown}
    >
      {children}
    </NavElem>
  )
}
