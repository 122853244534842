import styled from 'styled-components'

export const LayoutStyled = styled.div`
  width: 100vw;
  height: 100dvh;
  overflow-x: hidden;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: auto 1fr auto;
`

export const MainStyled = styled.main`
  width: 100%;
  flex-grow: 1;
  position: relative;
`

export const FallbackStyled = styled.div`
  position: absolute;
  inset: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`
