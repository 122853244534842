import { lazy } from 'react'
import { createBrowserRouter, Outlet } from 'react-router-dom'
import { PAGE_ROUTES } from './pageRoutes'
import { Layout } from '../components/widgets'
import Home from '../components/pages/Home'

const AboutLazy = lazy(() => import('../components/pages/About'))
const BlogLazy = lazy(() => import('../components/pages/Blog'))
const BlogItemLazy = lazy(() => import('../components/pages/BlogItem'))
const ContactLazy = lazy(() => import('../components/pages/Contact'))
const HowItsWorkLazy = lazy(() => import('../components/pages/HowItsWork'))
const PrivacyPolicyLazy = lazy(() => import('../components/pages/PrivacyPolicy'))
const TermsOfServiceLazy = lazy(() => import('../components/pages/TermsOfService'))

export const router = createBrowserRouter([
  {
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        path: PAGE_ROUTES.Home,
        element: <Home />,
      },
      {
        path: PAGE_ROUTES.HowItsWork,
        element: <HowItsWorkLazy />,
      },
      {
        path: PAGE_ROUTES.FrequentlyAskedQuestions,
        element: <AboutLazy />,
      },
      {
        path: PAGE_ROUTES.Blog,
        element: <BlogLazy />,
      },
      {
        path: PAGE_ROUTES.BlogItem,
        element: <BlogItemLazy />,
      },
      {
        path: PAGE_ROUTES.Contact,
        element: <ContactLazy />,
      },
      {
        path: PAGE_ROUTES.PrivacyPolicy,
        element: <PrivacyPolicyLazy />,
      },
      {
        path: PAGE_ROUTES.TermsOfService,
        element: <TermsOfServiceLazy />,
      },
    ],
  },
])
