import styled from 'styled-components'

export type ButtonTheme = 'primary' | 'secondary' | 'tertiary'

export const Button = styled.button<{ theme?: ButtonTheme }>`
  width: fit-content;
  padding: 10px 24px;
  border-radius: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;
  text-align: center;

  ${({ theme }) => {
    switch (theme) {
      case 'primary':
      default: {
        return `
          color: #fff;
          border: 3px solid #fff;
          background: linear-gradient(257.48deg, #27AAE1 38.89%, #1C75BC 73.94%);
          transition: background 0.3s;
          &:hover {
            background: linear-gradient(257.48deg, #1C75BC 21.89%, #27AAE1  59.94%)
          }
        `
      }
      case 'secondary': {
        return `
            color: #1C75BC;
            background: #fff;
            &:hover {
              background: #f5f5f5;
            }
          `
      }
      case 'tertiary': {
        return `
            color: #fff;
            background: #27AAE1;
            &:hover {
              background: #258cb8;
            }
          `
      }
    }
  }}
`
