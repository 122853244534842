import styled from 'styled-components'
import { breakpoints } from '../../../theme'

import Bg from '../../../assets/images/bg-main.png'
import BgMob from '../../../assets/images/bg-image-mobile.png'
import Bubbles from '../../../assets/images/bubbles.svg'
import BubblesTop from '../../../assets/images/bubbles-top.svg'
import BubblesBottom from '../../../assets/images/bubbles-bottom.svg'

import { CSSProperties } from 'react'

export const MainSectionStyled = styled.section`
  width: 100%;
  height: auto;
  min-height: calc(100dvh - 80px);
  background: linear-gradient(72.7deg, #1C75BC -2.11%, #1C75BC 44.01%, #27AAE1 99.3%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 160px 0 259px 0;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(${BubblesTop});
    background-size: cover;
    max-width: 382px;
    max-height: 421px;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url(${BubblesBottom});
    background-size: cover;
    max-width: 526px;
    max-height: 416px;
    width: 100%;
    height: 100%;
  }

  ${breakpoints.down('large')} {
    min-height: calc(100dvh - 55px);
  }

  ${breakpoints.down('medium')} {
    padding: 154px 0;
  }

  ${breakpoints.down('small')} {
    background: linear-gradient(257.48deg, #1C75BC 7.74%, #27AAE1 98.98%);

    &:before {
      content: "";
      position: absolute;
      left: initial;
      right: 0;
      bottom: 0;
      top: initial;
      background-image: url(${Bubbles});
      background-size: cover;
      max-width: 500px;
      max-height: 416px;
      width: 100%;
      height: 100%;
    }

    &:after {
      display: none;
    }
  }
`

export const MainSectionContainer = styled.div`
  width: 100%;
  ${breakpoints.down('large')} {
    padding: 0 16px;
  }
  z-index: 1;
`

export const SecondarySectionTemplate = styled.section<{
  background?: string
  bgSize?: {
    desktop: CSSProperties['backgroundSize']
    mobile: CSSProperties['backgroundSize']
  }
  fullHeight?: boolean
}>`
  ${({ fullHeight }) => fullHeight && `min-height: 100dvh;`}
  
  padding: 80px 0 62px;
  background: ${({ background }) => background ?? '#fff'};
  background-size: ${({ bgSize }) => bgSize?.desktop ?? 'contain'};
  ${breakpoints.down('large')} {
    padding: 80px 16px 62px 16px;
  }
  background-position: center;
  ${breakpoints.down('medium')} {
    background-size: ${({ bgSize }) => bgSize?.mobile ?? 'auto'};
  }
`
