import styled from 'styled-components'
import { CSSProperties } from 'react'
import { breakpoints, MediaSizes } from '../../../theme'

export type StackProps = Partial<{
  maxWidth:
    | {
        mobile: CSSProperties['maxWidth']
        desktop: CSSProperties['maxWidth']
      }
    | CSSProperties['maxWidth']
  direction:
    | {
        mobile: CSSProperties['flexDirection']
        desktop: CSSProperties['flexDirection']
      }
    | CSSProperties['flexDirection']
  align:
    | {
        mobile: CSSProperties['alignItems']
        desktop: CSSProperties['alignItems']
      }
    | CSSProperties['alignItems']
  justify:
    | {
        mobile: CSSProperties['justifyContent']
        desktop: CSSProperties['justifyContent']
      }
    | CSSProperties['justifyContent']
  gap:
    | {
        mobile: CSSProperties['gap']
        desktop: CSSProperties['gap']
      }
    | CSSProperties['gap']
  wrap:
    | {
        mobile: CSSProperties['flexWrap']
        desktop: CSSProperties['flexWrap']
      }
    | CSSProperties['flexWrap']
  margin:
    | {
        mobile: CSSProperties['margin']
        desktop: CSSProperties['margin']
      }
    | CSSProperties['margin']
  padding:
    | {
        mobile: CSSProperties['padding']
        desktop: CSSProperties['padding']
      }
    | CSSProperties['padding']
}> & {
  useBreakpoint?: MediaSizes | string
}

const checkIsMediaSize = (value?: string): value is MediaSizes => ["huge", "large", "medium", "small"].includes(value ?? 'medium')

export const Stack = styled.div<StackProps>`
  width: 100%;
  display: flex;

  align-items: ${({ align }) =>
    typeof align === 'object' ? align.desktop : align};
  justify-content: ${({ justify }) =>
    typeof justify === 'object' ? justify.desktop : justify};
  gap: ${({ gap }) => (typeof gap === 'object' ? gap.desktop : gap)};
  flex-direction: ${({ direction }) =>
    typeof direction === 'object' ? direction.desktop : direction};
  flex-wrap: ${({ wrap }) => (typeof wrap === 'object' ? wrap.desktop : wrap)};
  margin: ${({ margin }) =>
    typeof margin === 'object' ? margin.desktop : margin};
  padding: ${({ padding }) =>
    typeof padding === 'object' ? padding.desktop : padding};
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === 'object' ? maxWidth.desktop : maxWidth};

  ${({ useBreakpoint }) => checkIsMediaSize(useBreakpoint) ? breakpoints.down(useBreakpoint ?? 'medium') : useBreakpoint} {
    align-items: ${({ align }) =>
      typeof align === 'object' ? align.mobile : align};
    justify-content: ${({ justify }) =>
      typeof justify === 'object' ? justify.mobile : justify};
    gap: ${({ gap }) => (typeof gap === 'object' ? gap.mobile : gap)};
    flex-direction: ${({ direction }) =>
      typeof direction === 'object' ? direction.mobile : direction};
    flex-wrap: ${({ wrap }) => (typeof wrap === 'object' ? wrap.mobile : wrap)};
    margin: ${({ margin }) =>
      typeof margin === 'object' ? margin.mobile : margin};
    padding: ${({ padding }) =>
      typeof padding === 'object' ? padding.mobile : padding};
    max-width: ${({ maxWidth }) =>
      typeof maxWidth === 'object' ? maxWidth.mobile : maxWidth};
  }
`
