import styled from 'styled-components'
import { breakpoints } from '../../../theme'

export const HeaderStyled = styled.header`
  width: 100%;
  padding: 15px 24px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${breakpoints.down('large')} {
    padding: 8px 24px;
  }
`

export const NavBarContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  flex-shrink: 0;
`
export const ScheduleButton = styled.button<{ isStatic?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
  
    background: #27AAE1;
    border-radius: 24px;
    &:hover {
        opacity: 0.7;
    }
`

