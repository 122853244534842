import React, { FC, PropsWithChildren, Suspense } from 'react'
import { LayoutStyled, MainStyled } from './styles'
import { Header, Footer } from '../../widgets'

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LayoutStyled>
      <Header />
      <MainStyled>
        <Suspense
          fallback={<></>}
        >
          {children}
        </Suspense>
      </MainStyled>
      <Footer />
    </LayoutStyled>
  )
}
