import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { StepCardProps } from './types'
import { Typography } from '../../common/Typography'
import { Stack } from '../../common'
import BubblesArrow from '../../../assets/icons/bubbles-arrow-arc.svg'
import Bubble from '../../../assets/images/bubble-empty.png'

const Card = styled.div`
  width: 280px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-image: url(${Bubble});
  background-size: cover;
  border-radius: 12px;
  padding: 33.67px 38px;
  position: relative;
  cursor: pointer;
  text-align: center;

  filter: saturate(0);
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: saturate(1);
    transform: scale(1.15);
  }
`

const Arrow = styled.div<Pick<StepCardProps, 'arrow'>>`
  position: relative;

  &:after {
    content: '';
    display: ${({ arrow }) => arrow ? 'block' : 'none'};
    position: absolute;
    top: ${({ arrow }) => arrow?.top || 'initial'};
    bottom: ${({ arrow }) => arrow?.bottom || 'initial'};
    left: ${({ arrow }) => arrow?.left || 'initial'};
    right: ${({ arrow }) => arrow?.right || 'initial'};
    width: 56px;
    height: 80px;
    background-image: url(${BubblesArrow});
    background-size: cover;
    z-index: 1;
    transform: ${({ arrow }) => arrow?.transform || 'initial'};
  }
`

export const StepCard: FC<StepCardProps> = ({
  Icon,
  title,
  description,
  direction,
  onHover,
  arrow,
}) => {
  const [isHovered, setHover] = useState(false);

  const onMouseEnter = () => {
    setHover(true)
    onHover?.({ title, description })
  }

  const onMouseLeave = () => {
    setHover(false)
    onHover?.({ title: '', description: '' })
  }

  return (
    <Stack
      direction={direction}
      align="center"
      justify="center"
      gap="50px"
      maxWidth="fit-content"
    >
      <Typography.Text size="large" as="h4" fontWeight={800} color="#fff" className={isHovered ? 'hide' : ''} style={{ textTransform: 'capitalize', flexShrink: 0 }}>
        {title}
      </Typography.Text>
      
      <Arrow arrow={arrow}>
        <Card
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {Icon && <Icon />}
        </Card>
      </Arrow>

    </Stack>
  )
}
