import React, { FC, PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'
import anime from 'animejs/lib/anime.es.js'

const BurgerMenuContainerStyled = styled.div`
  background: #fff;
  width: 100%;
  position: absolute;
  top: 60px;
  padding: 24px 0 53px;
  left: 0;
  z-index: 2;
  box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.09);
`
export const BurgerMenuContainer: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const body = document.querySelector('body')!
    body.style.overflowY = 'hidden'
    requestAnimationFrame(() => {
      anime({
        targets: '.burger-anime',
        easing: 'linear',
        scaleY: [0, 1],
        duration: 300,
      })
    })
    return () => {
      body.style.overflowY = 'auto'
    }
  }, [])
  return (
    <BurgerMenuContainerStyled className="burger-anime">
      {children}
    </BurgerMenuContainerStyled>
  )
}
