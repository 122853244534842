import styled, { WebTarget } from 'styled-components'
import { Link } from 'react-router-dom'
import { NavLinkProps } from './types'
import { breakpoints } from '../../../theme'
import DropDawnIcon from '../../../assets/icons/dropdown-icon.svg'

export const NavLinkStyled = styled(Link)<
  Pick<NavLinkProps, 'isActive' | 'inFooter' | 'isDropDown' | 'to'>
>`
  font-size: ${({ inFooter }) => (inFooter ? '18px' : '20px')};
  line-height: ${({ inFooter }) => (inFooter ? '27px' : '30px')};
  font-weight: ${({ inFooter }) => (inFooter ? 400 : 700)};
  color: ${({ isActive }) => (isActive ? '#1C75BC' : '#000')};
  padding: 12px;
  &:hover {
    opacity: 0.7;
  }
  position: relative;
  ${({isDropDown}) => (isDropDown && `
    padding-right: 20px;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 17px;
      width: 13px;
      height: 13px;
      background-image: url(${DropDawnIcon});
    }
  `)}
  ${breakpoints.down('large')} {
    font-size: 18px;
    line-height: 27px;
  }
`

export const NavLinkEmptyStyled = styled.span<
Pick<NavLinkProps, 'isActive' | 'inFooter' | 'isDropDown'>
>`
font-size: ${({ inFooter }) => (inFooter ? '18px' : '20px')};
line-height: ${({ inFooter }) => (inFooter ? '27px' : '30px')};
font-weight: ${({ inFooter }) => (inFooter ? 400 : 700)};
color: ${({ isActive }) => (isActive ? '#1C75BC' : '#000')};
padding: 12px;
&:hover {
  opacity: 0.7;
}
cursor: pointer;
position: relative;
${({isDropDown}) => (isDropDown && `
  padding-right: 20px;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 17px;
    width: 13px;
    height: 13px;
    background-image: url(${DropDawnIcon});
  }
`)}
${breakpoints.down('large')} {
  font-size: 18px;
  line-height: 27px;
}
`
