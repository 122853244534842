import React, { FC, PropsWithChildren, useEffect } from 'react'
import anime from 'animejs/lib/anime.es.js'
import { OpacityTransitionProps } from './types'

export const OpacityTransition: FC<
  PropsWithChildren<OpacityTransitionProps>
> = ({ children, delay = 0 }) => {
  useEffect(() => {
    requestAnimationFrame(() => {
      anime({
        direction: 'alternate',
        loop: false,
        autoplay: true,
        easing: 'easeInOutCubic',
        targets: '.opacity-anime',
        opacity: [0, 1],
        delay,
      })
    })
    // eslint-disable-next-line
  }, [])

  return <div className="opacity-anime">{children}</div>
}
