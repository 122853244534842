import React, { FC, useEffect, useState } from 'react'
import { useMediaQuery } from '@uidotdev/usehooks'
import { useLocation } from 'react-router'
import { NavBar, BurgerMenuContainer } from '../../entities'
import { BurgerButton, HeaderLogo, Stack } from '../../common'
import { breakpoints } from '../../../theme'
import { HeaderStyled, NavBarContainer, ScheduleButton } from './styles'
import { Typography } from '../../common/Typography'
import { openCalendlyWidget } from '../../../utils'

export const Header: FC = () => {
  const { pathname } = useLocation()
  const isSmall = useMediaQuery(
    breakpoints.down('large').replace('@media ', ''),
  )

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  return (
    <HeaderStyled>
      <Stack maxWidth="400px" align="center" justify="flex-start">
        <HeaderLogo />
      </Stack>
      {!isSmall && (
        <NavBarContainer>
          <NavBar />
        </NavBarContainer>
      )}
      {!isSmall && (
        <Stack maxWidth="400px" align="center" justify="center">
          <ScheduleButton onClick={openCalendlyWidget}>
            <Typography.Text size="small" color="#fff" fontWeight={800}>
              Request an Audit
            </Typography.Text>
          </ScheduleButton>
        </Stack>
      )}

      {isSmall && (
        <>
          <BurgerButton
            isOpen={isOpen}
            onClick={() => {
              setIsOpen((prev) => !prev)
            }}
          />
          {isOpen && (
            <BurgerMenuContainer>
              <NavBar inBurger />
            </BurgerMenuContainer>
          )}
        </>
      )}
    </HeaderStyled>
  )
}
