import React, { FC, PropsWithChildren, useEffect } from 'react'
import anime from 'animejs/lib/anime.es.js'

export const TopDownOpacity: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    requestAnimationFrame(() => {
      anime({
        targets: '.anime-top-down-opacity',
        opacity: [0, 1],
        translateY: ['-30px', 0],
        direction: 'alternate',
        loop: false,
        autoplay: true,
        easing: 'easeInOutCubic',
      })
    })
  }, [])

  return <div className="anime-top-down-opacity">{children}</div>
}
