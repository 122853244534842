import React, { FC } from 'react'
import BubblesCircle from '../../../assets/images/bubbles-circle.svg'
import { Typography } from '../../common/Typography'
import styled from 'styled-components'


const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 380px;
  flex-shrink: 0;
  height: 280px;
  background-image: url(${BubblesCircle});
  background-repeat: no-repeat;
  background-position: center;
`

export const CompanyCard: FC<{ title?: string, description?: string }> = ({ title, description }) => {
  return (
    <Card>
      <Typography.Title size="small" color="#fff" as="h3">
        {title}
      </Typography.Title>
      <Typography.Text size="small" color="#fff" as="h4" style={{lineHeight: 1.2}}>
        {description}
      </Typography.Text>
    </Card>
  )
}
