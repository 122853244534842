import {CALENDLY_WIDGET_LINK, LABELS, LINE_BREAKE, MAIL, SUBJECT} from '../data'

export const openCalendlyWidget = () =>
  window.open(CALENDLY_WIDGET_LINK, '_self')


export const createMailToUrl = (data: { [key: string]: string }): string => {
  const body = Object.entries(LABELS).reduce(
    (acc, [key, label]) =>
      data[key] ? `${acc}${acc && LINE_BREAKE}${label}${data[key]}` : acc,
    '',
  )
  return `mailto:${MAIL}?subject=${SUBJECT}&body=${body}`
}
