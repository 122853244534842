import React, { FC } from 'react'
import styled from 'styled-components'
import { HeaderLogo, Stack } from '../../common'
import { Typography } from '../../common/Typography'
import { NavBar } from '../../entities'

import { ReactComponent as XIcon } from '../../../assets/icons/x-icon.svg'
import { ReactComponent as InIcon } from '../../../assets/icons/linked-in-icon.svg'
import { ReactComponent as FB } from '../../../assets/icons/facebook-icon.svg'
import { breakpoints } from '../../../theme'
import { useMediaQuery } from '@uidotdev/usehooks'
import { PAGE_ROUTES } from '../../../router'

const SOCIAL_MEDIA_LINKS = [
  {
    Icon: InIcon,
    url: 'https://www.linkedin.com/company/rcmxsystems',
  },
  {
    Icon: FB,
    url: 'https://www.facebook.com/profile.php?id=61558998801071',
  },
]

const FooterStyled = styled.footer`
  width: 100%;
  background: #ebebeb;
  padding: 60px 250px;
  & .second-column {
    flex-shrink: 0;
  }
  ${breakpoints.down('huge')} {
    padding: 40px 16px 50px 16px;
  }
`
const FooterPostLink = styled.a`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #1c75bc;
  &:hover {
    opacity: 0.7;
  }
`
const RightsText = styled(Typography.Text)`
  font-size: 20px;
  line-height: 28px;
`

const SocialMediaLink = styled.a`
  color: #000;
  background: #fff;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
`

const PolicyLink = styled.a`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  &:hover {
    opacity: 0.7;
  }
`
export const Footer: FC = () => {
  const isSmall = useMediaQuery(
    breakpoints.down('medium').replace('@media ', ''),
  )
  return (
    <FooterStyled>
      <Stack
        useBreakpoint="large"
        direction="column"
        align="center"
        justify="center"
        gap="30px"
      >
        <Stack
          useBreakpoint="large"
          className="row"
          direction={{ mobile: 'column', desktop: 'row' }}
          justify="space-between"
          align={{ mobile: 'center', desktop: 'center' }}
          gap={{ mobile: '42px', desktop: '0' }}
          padding={{ mobile: '0px', desktop: '10px' }}
        >
          <Stack
            useBreakpoint="large"
            direction={{ mobile: 'column', desktop: 'row' }}
            maxWidth="fit-content"
            align="center"
            justify="center"
            gap="36px"
          >
            <HeaderLogo isStatic size='l' />

            <RightsText size="extraSmall" fontWeight={300}>
              ©{new Date().getFullYear() } RCM-X.
            </RightsText>

            <Stack
              useBreakpoint="large"
              direction={{ mobile: 'column', desktop: 'row' }}
              maxWidth="fit-content"
              align="center"
              justify="center"
              gap={{ mobile: '18px', desktop: '36px' }}
            >
              <PolicyLink href={PAGE_ROUTES.PrivacyPolicy}>Privacy Policy</PolicyLink>
              <PolicyLink href={PAGE_ROUTES.TermsOfService}>Terms of Use</PolicyLink>
            </Stack>


          </Stack>

          <Stack
              useBreakpoint="large"
              direction="row"
              maxWidth="fit-content"
              align="center"
              justify="center"
              gap="18px"
            >
              {SOCIAL_MEDIA_LINKS.map(({ Icon, url }) => (
                <SocialMediaLink href={url} target="_blank">
                  <Icon style={{ width: '100%', height: '100%' }}/>
                </SocialMediaLink>
              ))}
            </Stack>
        </Stack>
      </Stack>
    </FooterStyled>
  )
}
