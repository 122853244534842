import React, { FC } from "react";
import styled from "styled-components";
import { ServiceCardProps } from "./types";
import { Button, Stack } from "../../common";
import { Typography } from "../../common/Typography";
import { useMediaQuery } from "@uidotdev/usehooks";

const CardLogo = styled.img`
  max-width: 48px;
`;
const GrowContainer = styled(Stack)`
  flex-grow: 1;
`;
const StretchSelf = styled(Stack)`
  align-self: stretch;
  @media screen and (max-width: 920px) {
    align-self: center;
  }
`;

const CardWrap = styled.div`
  dispaly: grid;
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: 62px 1fr;
  grid-template-rows: 100% 1fr;
  gap: 18px;
  grid-template-areas:
    "img content"
    ". content"
    ". button";
  max-width: 360px;
`;

export const ServiceCard: FC<ServiceCardProps> = ({
  imgUrl,
  title,
  description,
  onButtonClick,
  buttonLabel,
  className: propsClassName,
}) => {
  const isMobile = useMediaQuery("screen and (max-width: 920px)");
  const isSmallHeight = useMediaQuery("screen and (max-height: 1080px)");

  return (
    <StretchSelf className="strech" maxWidth="fit-content" align="stretch">
      <Stack
        useBreakpoint="large"
        className={propsClassName}
        direction="row"
        align="flex-start"
        justify="flex-start"
        gap="18px"
        maxWidth="360px"
      >
        {!isMobile && <CardLogo src={imgUrl} />}
        <StretchSelf
          useBreakpoint="@media screen and (max-width: 920px)"
          direction="column"
          justify={{ mobile: "center", desktop: "start" }}
          align={{ mobile: "center", desktop: "stretch" }}
          gap={{ mobile: "24px", desktop: isSmallHeight ? "80px" : "60px" }}
        >
          {isMobile && <CardLogo src={imgUrl} />}
          <Typography.Text
            textAlign={isMobile ? "center" : "left"}
            size={isMobile ? "small" : "large"}
            fontWeight={800}
            as="h4"
            color="#fff"
            style={{ textTransform: "capitalize" }}
          >
            {title}
          </Typography.Text>
          <GrowContainer
            useBreakpoint="@media screen and (max-width: 920px)"
            direction="column"
            justify={{ mobile: "center", desktop: "start" }}
            align={{ mobile: "center", desktop: "start" }}
            gap={{ mobile: "24px", desktop: "80px" }}
          >
            <GrowContainer>
              <Typography.Text
                textAlign={isMobile ? "center" : "left"}
                fontWeight={400}
                size={isMobile ? "extraSmall" : "medium"}
                color="#fff"
                style={{ textWrap: "balance", ...(isMobile && { padding: "0 10px" }) }}
              >
                {description}
              </Typography.Text>
            </GrowContainer>
            {isMobile && (
              <Button
                className={`${propsClassName}-button`}
                onClick={onButtonClick}
                theme="tertiary"
              >
                {buttonLabel}
              </Button>
            )}
          </GrowContainer>
        </StretchSelf>
      </Stack>
    </StretchSelf>
  );
};
