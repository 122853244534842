import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { SecondarySectionTemplate } from "../styles";
import DarkBlue from "../../../../assets/images/what-we-do-bg.png";
import DarkBlueBG from "../../../../assets/images/blue-what-we-do-bg.svg";
import DarkBlueMob from "../../../../assets/images/main-third-section-mobile-bg.png";
import { Stack, Button } from "../../../common";
import { Typography } from "../../../common/Typography";
import { ServiceCardProps } from "../../../entities/ServiceCard/types";
import { openCalendlyWidget } from "../../../../utils";

import MedCard from "../../../../assets/icons/med-card-icon.png";
import Profile from "../../../../assets/icons/profile-icon.png";
import Seal from "../../../../assets/icons/seal-icon.png";
import { ServiceCard } from "../../../entities/ServiceCard";
import { useInView } from "react-intersection-observer";
import anime from "animejs/lib/anime.es.js";
import { useMediaQuery } from "@uidotdev/usehooks";
import { breakpoints } from "../../../../theme";

const SERVICE_CARDS: ServiceCardProps[] = [
  {
    imgUrl: MedCard,
    title: "Medical Billing",
    description:
      "RCM-X performs all aspects of\u00a0Medical Billing on\u00a0your behalf, doing everything from Claim submission, Denial management, Payer appeals, Patient Collections and more.",
    buttonLabel: "Learn More",
    onButtonClick: openCalendlyWidget,
  },
  {
    imgUrl: Profile,
    title: "Prior Authorization",
    description:
      "RCM-X runs all Prior Auths and Eligibility checks days in\u00a0advance of\u00a0appointments. Results entered directly into your EMR. Real-time status results within 5\u00a0min.",
    buttonLabel: "Learn More",
    onButtonClick: openCalendlyWidget,
  },
  {
    imgUrl: Seal,
    title: "Contract Renegotiation",
    description:
      "Rcm-x includes a\u00a0complimentary contract Health overview. Intensive credentialing and contract renegotiation offered as\u00a0an\u00a0add-on.",
    buttonLabel: "Learn More",
    onButtonClick: openCalendlyWidget,
  },
];

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1320px;
  width: 100%;
  gap: 24px;
`;

const ButtonContainer = styled.div`
  max-width: 360px;
  width: 100%;
  text-align: left;
  padding-left: 64px;
`;

export const ThirdSection: FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const isSmall = useMediaQuery(breakpoints.down("medium").replace("@media ", ""));

  const isMobile = useMediaQuery("screen and (max-width: 920px)");
  const isSmallHeight = useMediaQuery("screen and (max-height: 1080px)");

  const cardsAnimation = useRef<any>(null);
  const cardButtonAnimation = useRef<any>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      cardsAnimation.current = anime({
        targets: [".service-card-anime"],
        easing: "easeInOutCubic",
        translateX: ["-200px", 0],
        opacity: [0, 1],
        delay: (_, i) => 200 * i,
        duration: 2000,
      });
      cardButtonAnimation.current = anime({
        targets: [".service-card-anime-button"],
        easing: "easeInOutCubic",
        translateX: ["-200px", 0],
        opacity: [0, 1],
        delay: (_, i) => 400 * (i + 1),
        duration: 2000,
      });
    });
  }, []);

  useEffect(() => {
    if (inView) {
      cardsAnimation.current?.play();
      cardButtonAnimation.current?.play();
    }
  }, [inView]);
  return (
    <SecondarySectionTemplate
      background={`url(${DarkBlueBG}), radial-gradient(at bottom center, #394043  0%, #0c171c 40%);`}
      bgSize={{ desktop: "cover", mobile: "cover" }}
      fullHeight
      style={{ display: "flex", paddingBottom: "80px", alignItems: isSmallHeight ? "normal" : "center" }}
    >
      <Stack
        direction="column"
        gap={{ mobile: "80px", desktop: "80px" }}
        align="center"
        justify="space-between"
        style={{maxHeight: isSmallHeight ? 'auto' : '920px'}}
      >
        <Typography.Title size="small" color="#fff" as="h2">
          What we do
        </Typography.Title>
        <div style={{ width: "100%", padding: isSmall ? "0" : "0 40px" }} ref={ref}>
          <Stack
            maxWidth="1320px"
            useBreakpoint="@media screen and (max-width: 920px)"
            direction={{ mobile: "column", desktop: "row" }}
            gap={{ mobile: "64px", desktop: "24px" }}
            align={{ mobile: "center", desktop: "start" }}
            justify="space-between"
          >
            {SERVICE_CARDS.map((card) => (
              <ServiceCard className="service-card-anime" {...card} />
            ))}
          </Stack>
        </div>
        {!isMobile && (
          <ButtonsWrap className="service-card-anime" style={{marginTop: isSmallHeight ? "0" : "-20px"}}>
            {SERVICE_CARDS.map(({ onButtonClick, buttonLabel }) => (
              <ButtonContainer>
                {" "}
                <Button onClick={onButtonClick} theme="tertiary">
                  {buttonLabel}
                </Button>
              </ButtonContainer>
            ))}
          </ButtonsWrap>
        )}
      </Stack>
    </SecondarySectionTemplate>
  );
};
