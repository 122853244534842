import React, { FC, useEffect, useRef } from 'react'

import DotsBg from '../../../../assets/images/dots.svg'
import { SecondarySectionTemplate } from '../styles'
import { Typography } from '../../../common/Typography'
import { Button, Stack } from '../../../common'
import { openCalendlyWidget } from '../../../../utils'
import { CardProps } from '../../../entities/Card/types'

import Plug from '../../../../assets/icons/plug-icon.png'
import Sun from '../../../../assets/icons/sun-icon.png'
import Customize from '../../../../assets/icons/customize-icon.png'
import { Card } from '../../../entities'
import { useInView } from 'react-intersection-observer'
import anime from 'animejs/lib/anime.es.js'
import { OpacityTransition } from '../../../animations'

const CARDS: CardProps[] = [
  {
    imgSrc: Plug,
    title: 'Plug and Play',
    description:
      'With over 120 EMR integrations, we launch our services behind the scenes without disrupting day-to-day operations.',
  },
  {
    imgSrc: Sun,
    title: 'Complimentary Audit',
    description: 'At no additional cost, RCM-X will analyze practice records and identify where and how reimbursements are being lost.',
  },
  {
    imgSrc: Customize,
    title: 'Customization',
    description:
      'Freely choose which services to add or exclude from your package so you only pay for the functionalities you need.',
  },
]

export const FourthSection: FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })

  const cardsAnimation = useRef<any>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      cardsAnimation.current = anime({
        targets: ['.card-anime'],
        easing: 'easeInOutCubic',
        translateY: ['150px', 0],
        opacity: [0, 1],
        duration: 2000,
        delay: (_, i) => 200 * i,
      })
    })
  }, [])
  useEffect(() => {
    if (inView) {
      cardsAnimation.current?.play()
    }
  }, [inView])
  return (
    <SecondarySectionTemplate bgSize={{ mobile: 'auto', desktop: 'auto' }} background={`url(${DotsBg})`}>
      <Stack direction="column" gap={{ mobile: "24px", desktop: "80px" }}>
        <Typography.Title size="small" as="h2">
          The RCM-X difference
        </Typography.Title>
        <div ref={ref} className="cards-container-anime">
          <Stack
            useBreakpoint="@media screen and (max-width: 860px)"
            direction={{ mobile: 'column', desktop: 'row' }}
            align={{ mobile: 'center', desktop: 'flex-start' }}
            justify="center"
            gap={{ mobile: '24px', desktop: '100px' }}
            maxWidth="1400px"
            margin="0 auto"
          >
            {CARDS.map((card) => (
              <Card
                alignCenterMobile
                useBreakpoint="@media screen and (max-width: 860px)"
                className="card-anime"
                key={card.title}
                maxWidth={{ mobile: '100%', desktop: '400px' }}
                {...card}
              />
            ))}
          </Stack>
        </div>
        <Stack justify="center" align="center">
          <OpacityTransition>
            <Button onClick={openCalendlyWidget} theme="tertiary">
              Talk To Sales
            </Button>
          </OpacityTransition>
        </Stack>
      </Stack>
    </SecondarySectionTemplate>
  )
}
