import React, { FC, useEffect, useRef, useState } from 'react'
import { AccordionProps } from './types'
import styled from 'styled-components'
import { Typography } from '../Typography'
import { ReactComponent as Chevrone } from '../../../assets/icons/chevrone-right-icon.svg'
import anime from 'animejs/lib/anime.es.js'

const DetailsStyled = styled.details`
  width: 100%;
  list-style: none;

  & summary {
    background: #27aae1;
    color: #fff;
    padding: 12px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background 0.3s;

    & svg {
      transition: rotate 0.3s;
    }
  }
  &[open] summary {
    background: #1c75bc;
    & svg {
      rotate: 90deg;
    }
  }

  & .text {
    padding: 28px 24px 10px 24px;
  }
`

const SummaryText = styled(Typography.Text)`
  max-width: calc(100% - 20px);
`

const TextContainer = styled.div`
  height: 0;
  overflow: hidden;
`

export const Accordion: FC<AccordionProps> = ({
  summary,
  details,
  accordionUid,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    requestAnimationFrame(() => {
      anime({
        easing: 'easeInOutCubic',
        targets: [`#${accordionUid} .text-container`],
        height: [0, `${textRef.current?.clientHeight}px`],
        duration: 200,
      })
    })
  }, [isOpen, accordionUid])

  return (
    <DetailsStyled id={accordionUid} open={isOpen}>
      <summary
        onClick={(e) => {
          e.preventDefault()
          if (isOpen) {
            anime({
              easing: 'easeInOutCubic',
              targets: [`#${accordionUid} .text-container`],
              height: [`${textRef.current?.clientHeight}px`, 0],
              duration: 200,
            }).finished.then(() => {
              setIsOpen(false)
            })
          } else {
            setIsOpen(true)
          }
        }}
      >
        <SummaryText
          size="medium"
          textAlign="left"
          color="#fff"
          fontWeight={600}
        >
          {summary}
        </SummaryText>
        <Chevrone />
      </summary>
      <TextContainer className="text-container">
        <Typography.Text ref={textRef} className="text" textAlign="left" size="small">
          {details}
        </Typography.Text>
      </TextContainer>
    </DetailsStyled>
  )
}
