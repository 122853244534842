import React, { FC, useEffect } from 'react'
import anime from 'animejs/lib/anime.es.js'
import { MainSection } from './MainSection'
import { SecondSection } from './SecondSection'
import { ThirdSection } from './ThirdSection'
import { FourthSection } from './FourthSection'

const words = ['Payments.', 'Financing.', 'Advanced Authentication.']
export const Home: FC = () => {
  useEffect(() => {
    requestAnimationFrame(() => {
      anime({
        targets: ['.bullet-0-anime', '.bullet-1-anime', '.bullet-2-anime'],
        opacity: [0, 1],
        easing: 'easeInOutCubic',
        delay: (_, i) => 150 * i,
      }).finished.then(() => {
        anime({
          targets: '.text-anime',
          opacity: [0, 1],
          easing: 'easeInOutCubic',
        })
      })
    })
  }, [])
  return (
    <>
      <MainSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
    </>
  )
}
