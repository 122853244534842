import React, { FC, useEffect, useRef } from 'react'
import anime from 'animejs/lib/anime.es'
import { MainSectionContainer, MainSectionStyled } from '../styles'
import { Typography } from '../../../common/Typography'
import { Button, Stack } from '../../../common'
import { openCalendlyWidget } from '../../../../utils'
import { useInView } from 'react-intersection-observer'

const WORDS = [
  'Medical Billing, ',
  'Patient Collections, ',
  'Prior Authorizations',
]

export const MainSection: FC = () => {
  const { ref, inView } = useInView({ triggerOnce: true })


  const topDownAnimation = useRef<any>(null)
  const opacityAnimation = useRef<any>(null)
  const bulletsAnimation = useRef<any>(null)
  const secondaryTextAnimation = useRef<any>(null)

  useEffect(() => {

    requestAnimationFrame(() => {
      topDownAnimation.current = anime({
        targets: '.title-top-down-anime',
        opacity: [0, 1],
        translateY: ['-45px', 0],
        direction: 'alternate',
        loop: false,
        autoplay: false,
        easing: 'linear',
        duration: 1200,
        delay: 400
      })
      opacityAnimation.current = anime({
        direction: 'alternate',
        loop: false,
        autoplay: false,
        easing: 'easeInOutCubic',
        targets: ['.title-opacity-anime', '.button-opacity-anime'],
        opacity: [0, 1],
        duration: 1500,
        delay: 400,
      })
      bulletsAnimation.current = anime({
        targets: ['.bullet-0-anime', '.bullet-1-anime', '.bullet-2-anime'],
        opacity: [0, 1],
        easing: 'easeInOutCubic',
        autoplay: false,
        duration: 2000,
        delay: (_, i) => 300 * i,
      })
      secondaryTextAnimation.current = anime({
        direction: 'alternate',
        loop: false,
        autoplay: false,
        easing: 'easeInOutCubic',
        targets: ['.secondary-text-anime'],
        opacity: [0, 1],
        duration: 1500,
        delay: 1400,
      })
    })
  }, [])

  useEffect(() => {
    if (inView) {
      topDownAnimation.current?.play()
      opacityAnimation.current?.play()
      bulletsAnimation.current?.play()
      secondaryTextAnimation.current?.play()
    }
  }, [inView])

  return (
    <MainSectionStyled>
      <MainSectionContainer ref={ref}>
        <Stack direction="column" gap="54px" align="center" justify="center">
          <Stack direction="column" gap="24px" align="center" justify="center">
            <div>
              <Typography.Title size="large" className="title-top-down-anime" color="#fff">
                The Modern End to End
              </Typography.Title>
              <Typography.Title size="large" className="title-opacity-anime" color="#fff">Revenue Cycle Solution</Typography.Title>
            </div>
            <div>
              {WORDS.map((word, i) => (
                <Typography.Text
                  size="large"
                  fontWeight={600}
                  as="span"
                  key={word}
                  color="#fff"
                  className={`bullet-${i}-anime`}
                >
                  {word}
                </Typography.Text>
              ))}
            </div>
          </Stack>
          <Stack
            direction="column"
            gap="36px"
            align="center"
            justify="center"
            maxWidth="763px"
          >
            <Typography.Text
              size="large"
              color="#fff"
              fontWeight={700}
              className="secondary-text-anime"
            >
              Automate billing and back-office functions <br /> out
              of your business so you can focus on patient care.
            </Typography.Text>
            <Button className="button-opacity-anime" theme="secondary" onClick={openCalendlyWidget}>
              Contact Us
            </Button>
          </Stack>
        </Stack>
      </MainSectionContainer>
    </MainSectionStyled>
  )
}
