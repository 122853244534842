export const CALENDLY_WIDGET_LINK = 'https://calendly.com/rcm-x/30min'
export const MAIL = 'test@mail.com'
export const SUBJECT = 'RCM-X interestet'
export const LABELS = {
    name: 'Name: ',
    email: 'Email: ',
    phone: 'Phone number: ',
    company: 'Company name: ',
    city: 'City: ',
    country: 'Country: ',
    hear: 'How did you hear about us: ',
    help: 'What can we help you with: ',
}

export const LINE_BREAKE = '%0D%0A'
