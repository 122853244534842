import styled from 'styled-components'
import { breakpoints } from '../../../theme'
import { CSSProperties } from 'react'

export type FontSizes = 'large' | 'medium' | 'small' | 'extraSmall'
// 24px 20px 18px

const TEXT_SIZES: { [key in FontSizes]: string } = {
  large: `
    font-size: 24px;
    line-height: 36px;
  `,
  medium: `
    font-size: 20px;
    line-height: 30px;
  `,
  small: `
    font-size: 18px;
    line-height: 27px;
  `,
  extraSmall: `
    font-size: 16px;
    line-height: 24px;
  `,
}

const TITLE_SIZES: { [key in FontSizes]?: string } = {
  large: `
    font-size: 70px;
    line-height: 72px;
    font-weight: 800;
    ${breakpoints.down('medium')} {
      font-size: 40px;
      line-height: 48px;
    }
  `,
  medium: `
    font-size: 54px;
    line-height: 72px;
    font-weight: 800;
    ${breakpoints.down('medium')} {
      font-size: 40px;
      line-height: 48px;
    }
  `,
  small: `
    font-size: 42px;
    line-height: 63px;
    font-weight: 800;
    ${breakpoints.down('medium')} {
      font-size: 40px;
      line-height: 48px;
    }
  `,
}

export const Title = styled.h1<{
  color?: string
  textAlign?: CSSProperties['textAlign']
  size?: FontSizes
}>`
  text-transform: capitalize;
  font-style: normal;
  color: ${({ color }) => color ?? '#000'};
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  ${({ size }) => TITLE_SIZES[size ?? 'large']}
`

export const Text = styled.p<{
  color?: string
  textAlign?: CSSProperties['textAlign']
  fontWeight?: CSSProperties['fontWeight']
  size?: FontSizes
}>`
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: ${({ color }) => color ?? '#000'};
  font-weight: ${({ fontWeight }) => fontWeight ?? 500};
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  ${({ size }) => TEXT_SIZES[size ?? 'medium']}
`
