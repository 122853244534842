import { BREAKPOINTS } from "./constants"
import { MediaSizes } from "./types"

export const breakpoints = {
  values: BREAKPOINTS,
  up: function (breakpoint: MediaSizes) {
    return `@media screen and (min-width: ${this.values[breakpoint]}px)`
  },
  down: function (breakpoint: MediaSizes) {
      return `@media screen and (max-width: ${this.values[breakpoint]}px)`
  },
  between: function (start: MediaSizes, end: MediaSizes) {
    return `@media screen and (min-width: ${this.values[start]}px) and (max-width: ${this.values[end]}px)`
  },
}
