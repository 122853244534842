import React, { FC } from 'react'
import { Stack } from '../../common'
import { CardProps } from './types'
import styled from 'styled-components'
import { Typography } from '../../common/Typography'
import { useMediaQuery } from '@uidotdev/usehooks'

const CardLogo = styled.img`
  max-width: 48px;
`
const PointsList = styled.ul`
  margin-left: 20px;
`
export const Card: FC<CardProps> = ({
  imgSrc,
  title,
  description,
  points,
  className: propsClassName,
  maxWidth,
  useBreakpoint,
  alignCenterMobile,
}) => {
  const isMobile = useMediaQuery('screen and (max-width: 920px)')
  
  return (
    <Stack
      useBreakpoint={useBreakpoint}
      className={propsClassName}
      maxWidth={maxWidth ?? { mobile: '100%', desktop: '520px' }}
      gap={{ mobile: '16px', desktop: '24px' }}
      direction="column"
      justify="flex-start"
      padding={{ mobile: '10px', desktop: '10px 40px' }}
    >
      <Stack justify={isMobile && alignCenterMobile ? 'center' : 'flex-start'}>
        <CardLogo src={imgSrc} />
      </Stack>
      <Stack direction="column" gap="16px">
        <Typography.Text textAlign={isMobile && alignCenterMobile ? 'center' : 'left'} size={isMobile ? 'small' : 'large'} fontWeight={800} as="h4" style={{ textTransform: 'capitalize' }}>
          {title}
        </Typography.Text>
        <div>
          <Typography.Text textAlign={isMobile && alignCenterMobile ? 'center' : 'left'} fontWeight={400} size={isMobile ? 'extraSmall' : 'medium'}>
            {description}
          </Typography.Text>
          {!!points?.length && (
            <PointsList>
              {points.map((point) => (
                <li key={point}>
                  <Typography.Text
                    textAlign="left"
                    fontWeight={400}
                    size="small"
                  >
                    {point}
                  </Typography.Text>
                </li>
              ))}
            </PointsList>
          )}
        </div>
      </Stack>
    </Stack>
  )
}
