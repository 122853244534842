import { PAGE_ROUTES } from '../../../router'

export const NAV_DATA = [
  {
    title: 'Home',
    url: PAGE_ROUTES.Home,
    isActive: false,
  },
  {
    title: 'How it works',
    url: PAGE_ROUTES.HowItsWork,
    isActive: false,
  },
  {
    title: 'About',
    isActive: false,
    subNav: [
      {
        title: 'Frequently Asked Questions',
        url: PAGE_ROUTES.FrequentlyAskedQuestions,
        isActive: false,
      },
      {
        title: 'Blog',
        url: PAGE_ROUTES.Blog,
        isActive: false,
      }
    ],
  },
  {
    title: 'Contact',
    url: PAGE_ROUTES.Contact,
    isActive: false,
  },
]
