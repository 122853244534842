import React, { FC } from 'react'
import styled from 'styled-components'
import { Typography } from '../../common/Typography'
import { Button, Stack } from '../../common'
import { useMediaQuery } from '@uidotdev/usehooks'
import { breakpoints } from '../../../theme'
import { SubmitHandler, useForm } from 'react-hook-form'
import { createMailToUrl } from '../../../utils'

const FormStyled = styled.form`
  background: #fff;
  border-radius: 12px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  ${breakpoints.down('medium')} {
    padding: 16px;
  }
`

const InputStyled = styled.input`
  width: 100%;
  padding: 8px 15px;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  &::placeholder {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #9c9c9c;
  }
`

const TextAreaStyled = styled.textarea`
  width: 100%;
  padding: 8px 15px;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  resize: none;
  &::placeholder {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #9c9c9c;
  }
`

const LabelStyled = styled.label<{ half?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: ${({ half }) => (half ? '50%' : '100%')};
`
const SubmitButton = styled(Button)`
  padding: 10px 42px;
`
export const Form: FC = () => {
  const isLarge = useMediaQuery(breakpoints.up('medium').replace('@media ', ''))
  const { register, handleSubmit } = useForm()

  const submitHandler: SubmitHandler<any> = (values) => {
    const url = createMailToUrl(values)
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <FormStyled onSubmit={handleSubmit(submitHandler)}>
      <Stack
        direction={{ mobile: 'column', desktop: 'row' }}
        gap={{ mobile: '18px', desktop: '10px' }}
      >
        <LabelStyled half={isLarge}>
          <Typography.Text size="small" fontWeight={600} textAlign="left">
            Name
          </Typography.Text>
          <InputStyled
            {...register('name', { required: true })}
            placeholder="Full Name"
          />
        </LabelStyled>
        <LabelStyled half={isLarge}>
          <Typography.Text size="small" fontWeight={600} textAlign="left">
            Email
          </Typography.Text>
          <InputStyled
            {...register('email', { required: true })}
            placeholder="Email"
            type="email"
          />
        </LabelStyled>
      </Stack>
      <Stack
        direction={{ mobile: 'column', desktop: 'row' }}
        gap={{ mobile: '18px', desktop: '10px' }}
      >
        <LabelStyled half={isLarge}>
          <Typography.Text size="small" fontWeight={600} textAlign="left">
            Phone Number
          </Typography.Text>
          <InputStyled {...register('phone')} placeholder="Phone Number" />
        </LabelStyled>
        <LabelStyled half={isLarge}>
          <Typography.Text size="small" fontWeight={600} textAlign="left">
            Company Name
          </Typography.Text>
          <InputStyled {...register('company')} placeholder="Company Name" />
        </LabelStyled>
      </Stack>
      <Stack
        direction={{ mobile: 'column', desktop: 'row' }}
        gap={{ mobile: '18px', desktop: '10px' }}
      >
        <LabelStyled half={isLarge}>
          <Typography.Text size="small" fontWeight={600} textAlign="left">
            Сity
          </Typography.Text>
          <InputStyled {...register('city')} placeholder="e.g. New York" />
        </LabelStyled>
        <LabelStyled half={isLarge}>
          <Typography.Text size="small" fontWeight={600} textAlign="left">
            Country
          </Typography.Text>
          <InputStyled
            {...register('country')}
            placeholder="e.g. United States"
          />
        </LabelStyled>
      </Stack>
      <LabelStyled>
        <Typography.Text size="small" fontWeight={600} textAlign="left">
          How did you hear about us?
        </Typography.Text>
        <TextAreaStyled rows={3} {...register('hear')} placeholder="Message" />
      </LabelStyled>
      <LabelStyled>
        <Typography.Text size="small" fontWeight={600} textAlign="left">
          What can we help you with?
        </Typography.Text>
        <TextAreaStyled rows={3} {...register('help')} placeholder="Message" />
      </LabelStyled>
      <Stack justify="flex-start">
        <SubmitButton theme="tertiary" type="submit">
          Send
        </SubmitButton>
      </Stack>
    </FormStyled>
  )
}
