import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as BurgerIcon } from '../../../assets/icons/ic-burger.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icons/ic-cross.svg'
import { BurgerButtonProps } from './types'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c75bc;
  width: 34px;
  height: 24px;
`

export const BurgerButton: FC<BurgerButtonProps> = ({ onClick, isOpen }) => {
  const iconStyle = {
    height: '100%',
    width: '100%',
  }

  return (
    <Button onClick={onClick}>{isOpen ? <CrossIcon style={iconStyle}/> : <BurgerIcon style={iconStyle}/>}</Button>
  )
}
