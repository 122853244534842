import React, { FC, useMemo, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { NavLink } from "../../common";
import { NavStyled } from "./styles";
import { ReactComponent as DropDawnIcon } from "../../../assets/icons/dropdown-icon.svg";
import { NAV_DATA } from "./constants";
import { NavBarProps } from "./types";
import { openCalendlyWidget } from "../../../utils";
import { Typography } from "../../common/Typography";
import { ScheduleButton } from "../../widgets/Header/styles";

const MenuContainer = styled.div`
  position: relative;
`;

const SubMenuContainer = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 12px);

  display: flex;
  flex-direction: column;
  width: max-content;

  padding: 16px 18px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px #0000005c;

  z-index: 1;
`;

export const NavBar: FC<NavBarProps> = ({ dontShowActive, inBurger, inFooter }) => {
  const location = useLocation();
  const navMenu = useMemo(
    () =>
      dontShowActive
        ? NAV_DATA
        : NAV_DATA.map((link) => ({
            ...link,
            isActive: location.pathname === link.url,
            ...(link.subNav?.length && {
              subNav: link.subNav.map(subNavLink => {
                return {...subNavLink, isActive: location.pathname === subNavLink.url}
              })
            })
          })),
    [location, dontShowActive],
  );

  const [subMenuOpen, setSubMenuOpen] = useState("");

  return (
    <NavStyled inBurger={inBurger} inFooter={inFooter}>
      {navMenu.map(({ title, url, isActive, subNav }) => {
        if (subNav?.length && inBurger) {
          return (
            <>
              <div
                key={title}
                onClick={() => setSubMenuOpen(subMenuOpen === title ? "" : title)}
              >
                <NavLink to={url} isActive={isActive} inFooter={inFooter} isDropDown>
                  {title}
                </NavLink>
              </div>

              {subMenuOpen === title && subNav.map((subItem) => (
                    <NavLink
                      key={subItem.title}
                      to={subItem.url}
                      isActive={subItem.isActive}
                      inFooter={inFooter}
                    >
                      {subItem.title}
                    </NavLink>
                  )
              )}
            </>
          );
        }

        if (subNav?.length && !inBurger) {
          return (
            <MenuContainer
              key={title}
              onMouseEnter={() => setSubMenuOpen(title)}
              onMouseLeave={() => setSubMenuOpen("")}
            >
              <NavLink
                key={title}
                to={url}
                isActive={isActive}
                inFooter={inFooter}
                isDropDown
              >
                {title}
              </NavLink>

              {subMenuOpen === title && (
                <SubMenuContainer>
                  {subNav.map((subItem) => (
                    <NavLink
                      key={subItem.title}
                      to={subItem.url}
                      isActive={subItem.isActive}
                      inFooter={inFooter}
                    >
                      {subItem.title}
                    </NavLink>
                  ))}
                </SubMenuContainer>
              )}
            </MenuContainer>
          );
        }

        return (
          <NavLink key={title} to={url} isActive={isActive} inFooter={inFooter}>
            {title}
          </NavLink>
        );
      })}
      {inBurger && (
        <ScheduleButton onClick={openCalendlyWidget}>
          <Typography.Text size="small" color="#fff" fontWeight={800}>
            Request an Audit
          </Typography.Text>
        </ScheduleButton>
      )}
    </NavStyled>
  );
};
